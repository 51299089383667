import React from "react"

class Close extends React.Component {
  postCV() {
    // const url = "https://app.close.com/hackwithus/d316a83d0deb6b64/"
    // const data = {
    //   first_name: "Ronda",
    //   last_name: "Lunn",
    //   email: "ronda@rondalunn.com",
    //   phone: "954-937-0291",
    //   cover_letter: `To Whom It May Concern:
    //           My name is Ronda Lunn, and I am applying for the position of Senior Software Engineer - Frontend. I obtained a Bachelor’s degree in Computer Science from the University of Miami in 2008. I also have a Bachelor’s degree in Biology and a Master of Public Health from Nova Southeastern University. I relocated to Charlotte from South Florida in June 2014. Before moving to Charlotte, I was working at a medical office in South Florida since February 2009. After relocating, I was asked to continue my position remotely. In this position, I handled multiple roles including designing, developing, and maintaining the company website, designing the logo and branding materials for the office, transcription of medical records, clinical analysis, assisting with patient care and education, clinical research, planning and implementing company policy, medical billing and coding, and other responsibilities that were delegated to me during my time there. 

    //           I have also worked as a freelance web developer and graphic designer since 2008. Most recently, I worked on a logo design and business cards for the Imagine Yourself podcast and converted my portfolio website to a progressive web app with React.js and Gatsby.js. Earlier this year, I started learning app development with Flutter. I also have some back end experience and would love to learn more.

    //           I am looking to obtain a position that will help me to further my career in web development and improve my skill set. I am a compassionate, energetic, creative, hard-working and resourceful person. I work well both as a team and individually. I am able to lead others as well as take direction and contribute effectively to a team. I have excellent attention to detail, and I am able to adapt to any situation.  I am a fast learner, and I work quickly and efficiently. 

    //           I believe that I would be a great fit for this role, and the position of Senior Software Engineer - Frontend would allow me to use my knowledge and skills to achieve my goals and those of Close. I am very excited about this opportunity, and I hope that we can work together. Thank you.

    //           Sincerely,

    //           Ronda Lunn`,
    //   urls: [
    //     `https://rondalunn.com`,
    //     `https://linkedin.com/in/rondalunn`,
    //     `https://github.com/rondalunn`,
    //   ],
    // }

  //   fetch(url, {
  //     method: "POST",
  //     body: JSON.stringify(data),
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json'
  //     }
  //   })
  //     .then(
  //       response => {
  //         console.log(response)
  //         if (response.ok) {
  //           return response.json()
  //         }
  //         throw new Error("Request Failed")
  //       },
  //       networkError => console.log(`Error: ${networkError.message}`)
  //     )
  //     .then(response => {
  //       console.log(response)
  //       return response
  //     })
   }

  render() {
    let myResponse = this.postCV()
    if (!myResponse) {
      myResponse = "Data unable to load"
    }
    return <div>{myResponse}</div>
  }
}

export default Close
